import React from 'react';
import { SingelKollektivTransportEntry } from 'types/SingelKollektivTransportEntry';
import style from './Popup.module.css';
import Text from 'components/Text/Text';

export interface Props {
  readonly avstandKollektivTransport: SingelKollektivTransportEntry[];
  readonly sykkelparkeringsplasser?: number;
  readonly parkeringsplasser?: number;
  readonly ladepunkter?: number;
}

export default function BeliggenhetPopup({
  avstandKollektivTransport,
  sykkelparkeringsplasser,
  parkeringsplasser,
  ladepunkter
}: Props) {
  return (
    <>
      <Text component="h3" variant="paragraph-header">
        Beliggenhet
      </Text>
      <div className={style.Avstander}>
        {avstandKollektivTransport?.map(({ avstandIMeter, kollektivtype }, index) => (
          <div key={index}>
            <Text variant="list-item">
              {kollektivtype}: <b style={{ color: 'var(--linkColor)' }}>{avstandIMeter} m</b>
            </Text>
          </div>
        ))}
      </div>
      {parkeringsplasser !== null && (
        <div>
          <Text variant="list-item">
            Antall parkeringsplasser: <b style={{ color: 'var(--linkColor)' }}>{parkeringsplasser} stk</b>
          </Text>
        </div>
      )}
      {sykkelparkeringsplasser !== null && (
        <div>
          <Text variant="list-item">
            Antall sykkelparkering: <b style={{ color: 'var(--linkColor)' }}>{sykkelparkeringsplasser} stk</b>
          </Text>
        </div>
      )}
      {ladepunkter !== null && (
        <div>
          <Text variant="list-item">
            Antall ladepunkter: <b style={{ color: 'var(--linkColor)' }}>{ladepunkter} stk</b>
          </Text>
        </div>
      )}
    </>
  );
}
