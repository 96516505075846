import React from 'react';
import Text from 'components/Text/Text';
import { numberWithSpaces } from 'js/format-helper';

export interface Props {
  readonly prisKvmMedTiltak?: number;
  readonly prisKvmUtenTiltak?: number;
}

export default function TomteprisPopup({ prisKvmMedTiltak, prisKvmUtenTiltak }: Props) {
  return (
    <>
      <Text component="h3" variant="paragraph-header">
        Tomtepris
      </Text>

      <Text variant="list-item">
        Pris med tiltak:
        <b style={{ color: 'var(--linkColor)' }}>{numberWithSpaces(prisKvmMedTiltak)} kr</b>
      </Text>
      <Text variant="list-item">
        Pris uten tiltak:
        <b style={{ color: 'var(--linkColor)' }}>{numberWithSpaces(prisKvmUtenTiltak)} kr</b>
      </Text>
    </>
  );
}
