/* eslint-disable react/display-name */
import KontaktpersonPanel from 'components/Kontaktperson/Kontaktperson';
import ScoreRow from 'components/ScoreRow/ScoreRow';
import React, { FC, useMemo } from 'react';
import { ArcgisServiceArea } from 'types/ArcgisServiceArea';
import { ArcgisSketch } from 'types/ArcgisSketch';
import { Kontaktperson } from 'types/Kontaktperson';
import RecommendationToolbar from '../components/RecommendationToolbar';
import SvarDetaljHeading from '../components/SvarDetaljHeading';
import SvarDetaljImages from '../components/SvarDetaljImages';
import { SvarDetaljHeaderValue } from '../components/SvarDetaljHeaderValue';
import style from '../SvarDetalj.module.css';
import SvarDetaljDokumenterPanel from '../components/SvarDetaljDokumenterPanel';
import { SvarTomtesok } from 'types/SvarTomtesok';
import { Fildata } from 'types/Fildata';
import PageLinkMenu, { Styles } from 'components/PageLinkMenu/PageLinkMenu';
import { LinkItem } from 'types/LinkItem';
export interface SvarTomteDetaljSide {
  readonly svar: SvarTomtesok;
  readonly kontaktperson: Kontaktperson;
  readonly visVurdering?: boolean;
  readonly overstyrVurderingUrl?: string;
  readonly settEllerFjernTilBefaringUrl?: string;
  readonly lastNedZipUrl?: string;
  readonly kommenterUrl?: string;
  readonly sketches?: ArcgisSketch[];
  readonly serviceAreas?: ArcgisServiceArea[];
  readonly pageLinks?: LinkItem[];
}

const SvarTomteDetaljSide: FC<SvarTomteDetaljSide> = ({
  svar,
  kontaktperson,
  visVurdering = false,
  overstyrVurderingUrl,
  settEllerFjernTilBefaringUrl,
  lastNedZipUrl,
  kommenterUrl,
  sketches,
  serviceAreas,
  pageLinks
}) => {
  const getBilder = useMemo(() => {
    const arr: Fildata[] = [];

    function addToArr(key) {
      if (svar?.bildeData && svar?.bildeData[key]) {
        arr.push(svar?.bildeData[key]);
      }
    }
    addToArr('bildeEnUrl');
    addToArr('bildeToUrl');
    addToArr('kartutsnittUrl');
    return arr;
  }, [svar?.bildeData]);

  return (
    <div className={style.SvarDetaljSide}>
      <SvarDetaljHeading
        naeringsmegler={svar.naeringsmeglerNavn}
        matrikkelInfo={svar?.matrikkelInfo}
      />
      {pageLinks && (
        <PageLinkMenu styles={Styles.onGrid} pageLinks={pageLinks} />
      )}
      <SvarDetaljImages
        matrikkelInfo={svar?.matrikkelInfo}
        overstyrtScoreHelhetsvurdering={svar.overstyrtScoreHelhetsvurdering}
        bilder={getBilder}
        sketches={sketches}
        serviceAreas={serviceAreas}
      />
      <ScoreRow
        visVurdering={visVurdering}
        overstyrVurderingUrl={overstyrVurderingUrl}
        scores={svar.scores}
        avstandKollektivTransport={svar.avstandKollektivTransport}
        reguleringsstatus={svar.reguleringsstatus}
        grunnforhold={svar.grunnforhold}
        infrastruktur={svar.infrastruktur}
      />
      <div className={style.Info}>
        <SvarDetaljHeaderValue
          header={'Kommuneplanstatus'}
          value={svar.kommuneplanStatus}
        />
        <SvarDetaljHeaderValue
          header={'Reguleringsstatus'}
          value={svar.reguleringsstatus}
        />
        <SvarDetaljHeaderValue
          header={'Beliggenhet'}
          value={svar.beliggenhet}
        />
        <SvarDetaljHeaderValue
          header={'Grunnforhold'}
          value={svar.grunnforhold}
        />
        <SvarDetaljHeaderValue
          header={'Infrastruktur'}
          value={svar.infrastruktur}
        />
        <SvarDetaljHeaderValue
          header={'Øvrige kommentarer'}
          value={svar.kommentarer}
        />
      </div>
      <div className={style.BorderedContainer}>
        <KontaktpersonPanel panelType="border" {...kontaktperson} />
        {/* <SvarDetaljUtleierPanel svar={svar} /> */}
        <SvarDetaljDokumenterPanel ekstraDokumenter={svar.ekstraDokumenter} />
      </div>

      <RecommendationToolbar
        lastNedZipUrl={lastNedZipUrl}
        overstyrtScoreHelhetsvurdering={svar.overstyrtScoreHelhetsvurdering}
        internKommentar={svar.internKommentar}
        tilBefaring={svar.tilBefaring}
        levertDatoUtc={svar.levertDatoUtc}
        overstyrVurderingUrl={overstyrVurderingUrl}
        settEllerFjernTilBefaringUrl={settEllerFjernTilBefaringUrl}
        kommenterUrl={kommenterUrl}
      />
    </div>
  );
};

export default SvarTomteDetaljSide;
