const useStatic = process.env.static;

const defaultFetchOptions = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  credentials: 'include'
};

async function parseResponse(response: Response) {
  const contentType = response.headers.get('content-type') ?? 'text';
  if (/application\/(problem\+)?json/.test(contentType)) {
    const json = await response.json();
    return { json, response };
  } else {
    const text = await response.text();
    return { text, json: {}, response };
  }
}

function handleResponse({ json }) {
  return json.payload || json;
}

function handleValidationError({ json, text, response }) {
  if (response.status === 400 && json.type === 'https://tools.ietf.org/html/rfc7231#section-6.5.1') {
    throw new ValidationError(json.errors);
  }

  if (!response.ok) {
    throw new Error(`Server error: ${text}`);
  }
  return { json, response };
}

function request(url: string, options) {
  if (useStatic) {
    var urlBaseUrl = url.split('?');
    var urlFormatted = `/static-site${urlBaseUrl[0]}.json?${urlBaseUrl[1]}`;
    if (options && (options.method === 'post' || options.method === 'put') && options.body) {
      //      options.method = 'get';
      //     options.body = null;
      // TODO: Validate requestmodel in options body
    }
    return fetch(urlFormatted.toLowerCase(), options).then(parseResponse).then(handleValidationError).then(handleResponse);
  } else {
    return fetch(url, options).then(parseResponse).then(handleValidationError).then(handleResponse);
  }
}

export class ValidationError {
  constructor(errors: Record<string, string[]>) {
    for (const [key, value] of Object.entries(errors)) {
      this[key] = value[0];
    }
  }

  [key: string]: string;
}

export function post<T = any>(endpoint: string, data: any = undefined): Promise<T> {
  return request(endpoint, {
    ...defaultFetchOptions,
    body: JSON.stringify(data),
    method: 'post'
  }) as Promise<T>;
}

export function upload<T = any>(endpoint: string, data: FormData): Promise<T> {
  return request(endpoint, {
    headers: {
      Accept: 'application/json'
    },
    credentials: 'include',
    body: data,
    method: 'post'
  }) as Promise<T>;
}

export function get<T = any>(endpoint: string) {
  return request(endpoint, defaultFetchOptions) as Promise<T>;
}

export function put(endpoint: string, data: any) {
  return request(endpoint, {
    ...defaultFetchOptions,
    body: JSON.stringify(data),
    method: 'put'
  });
}

// requests methods below discard embedding validation with the response.
// supports both FormData and js objects as payloads
function cleanRequest<T = any>(url, data?) {
  return fetch(url, {
    ...inferHeaders(data),
    credentials: 'include',
    method: 'POST',
    body: formatPayload(data ?? {}) // body data type must match "Content-Type" header
  }).then(parseResponse);
}

export function cleanPost<T = any>(url, data?) {
  return cleanRequest(url, data).then((result) => {
    if (!result.response.ok) {
      const errorKeyName = Object.keys(result.json.errors)[0];
      if (errorKeyName) throw new Error(result.json.errors[errorKeyName].join(' '));
    }
    return result.json;
  });
}

const inferHeaders = (data) => {
  return data instanceof FormData
    ? {
        headers: {
          Accept: 'application/json'
        }
      }
    : defaultFetchOptions;
};

const formatPayload = (data) => {
  return data instanceof FormData ? data : JSON.stringify(data);
};
