import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import { post } from 'js/api-helper';
import React from 'react';
import { FC, useState } from 'react';
import { SvarListeSide } from 'source/pages/Svar/SvarListe/SvarListeSide/SvarListeSide';

interface SvarItemModalProps {
  tableItems: SvarListeSide;
  setSvarItemModalData: (...args: any[]) => any;
  modalProps: ModalProps;
}

export interface ModalProps {
  isOpen: boolean;
  targetUrl?: string;
  tableItemId?: string;
  operation: ModalOperations;
}

export enum ModalOperations {
  'Delete',
  'Recind',
  'None'
}

const SvarItemModal: FC<SvarItemModalProps> = ({ tableItems, setSvarItemModalData, modalProps }) => {
  const [svarTableItems, setSvarTableItems] = useState({
    ...tableItems
  });

  function deleteSvarTableItemById(id) {
    Object.keys(svarTableItems).forEach((elem) => {
      const target = svarTableItems[elem].find((x) => x.id === id);

      if (target) {
        const itemsFiltered = svarTableItems[elem].filter((x) => x !== target);
        const newItems = (svarTableItems[elem] = itemsFiltered);
        setSvarTableItems((prevState) => ({
          ...prevState,
          newItems
        }));
      }
    });
  }

  async function deleteOffer() {
    if (modalProps.targetUrl && modalProps.tableItemId) {
      try {
        const res = await post(modalProps.targetUrl);
        if (res) {
          setSvarItemModalData((prevState) => ({
            ...prevState,
            isOpen: false
          }));

          deleteSvarTableItemById(modalProps.tableItemId);
        } else {
          console.log('error');
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  return (
    <Modal
      id="dropdown-menu-item-popup"
      title={'Er du sikker?'}
      open={modalProps.isOpen}
      onClose={() =>
        setSvarItemModalData((prevState) => ({
          ...prevState,
          isOpen: false
        }))
      }
    >
      {modalProps.operation === ModalOperations.Delete && (
        <>
          <p>Er du sikker på at du vil slette tilbudet? </p>
          <div className="ModalButtons">
            <Button onClick={() => deleteOffer()}>Slett</Button>
            <Button
              secondary
              onClick={() =>
                setSvarItemModalData((prevState) => ({
                  ...prevState,
                  isOpen: false
                }))
              }
            >
              Avbryt
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default SvarItemModal;
