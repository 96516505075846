/* eslint-disable react/display-name */
import React from 'react';
import { ArealEtPlan } from 'types/ArealEtPlan';
import { Score as ScoreType } from 'types/Score';
import { SingelKollektivTransportEntry } from 'types/SingelKollektivTransportEntry';
import BeliggenhetPopup from './Popups/BeliggenhetPopup';
import FleksibilitetPopup from './Popups/FleksibilitetPopup';
import LeieprisPopup from './Popups/LeieprisPopup';
import MiljoPopup from './Popups/MiljoPopup';
import OvertakelsePopup from './Popups/OvertakelsePopup';
import style from './Score.module.css';
import ScoreUnit from './ScoreUnit';
import { Miljodata } from 'types/Miljodata';
import { Parkeringsdata } from 'types/Parkeringsdata';
import TomteprisPopup from './Popups/TomteprisPopup';
import Text from 'components/Text/Text';

interface IScore {
  visVurdering: boolean;
  hideExpand?: boolean;
  readonly scores: ScoreType[];
  readonly avstandKollektivTransport: SingelKollektivTransportEntry[];
  readonly overstyrVurderingUrl?: string;
  readonly generellInfoLedigFraUtc?: string;
  readonly gjennomforingstidTilByggeklar?: string;
  readonly miljoData?: Miljodata;
  readonly parkeringsData?: Parkeringsdata;
  readonly universellUtforming?: string;
  readonly totaltAreal?: number;
  readonly ledigAreal?: number;
  readonly arealPerPlan?: ArealEtPlan[];
  readonly prisKvmMedTiltak?: number;
  readonly prisKvmUtenTiltak?: number;
  readonly felleskostKvm?: number;
  readonly mvaKompensasjonKvm?: number;
  readonly paslagFellesarealiProsent?: number;
  readonly kostKantineBidrag?: number;
  readonly overstyrtScoreHelhetsvurdering?: number;
  readonly bebygdStatus?: string;
  readonly reguleringsstatus?: string;
  readonly grunnforhold?: string;
  readonly infrastruktur?: string;
  readonly tilbudtAreal?: number;
  readonly utbyggingspotensialeBTA?: number;
}

const ScoreRow = ({
  visVurdering,
  hideExpand,
  scores,
  overstyrVurderingUrl,
  avstandKollektivTransport,
  miljoData,
  parkeringsData,
  generellInfoLedigFraUtc,
  totaltAreal,
  ledigAreal,
  gjennomforingstidTilByggeklar,
  arealPerPlan,
  universellUtforming,
  prisKvmUtenTiltak,
  prisKvmMedTiltak,
  felleskostKvm,
  kostKantineBidrag,
  mvaKompensasjonKvm,
  paslagFellesarealiProsent,
  bebygdStatus,
  tilbudtAreal,
  utbyggingspotensialeBTA,
  reguleringsstatus,
  grunnforhold,
  infrastruktur
}: IScore) => {
  const getPopupComponent = (type) => {
    switch (type) {
      case 'Beliggenhet':
        return (
          <BeliggenhetPopup
            gjennomforingstidTilByggeklar={gjennomforingstidTilByggeklar}
            avstandKollektivTransport={avstandKollektivTransport}
            ladepunkter={parkeringsData?.ladepunkter || 0}
            parkeringsplasser={parkeringsData?.parkeringsplasser || 0}
            sykkelparkeringsplasser={parkeringsData?.sykkelparkeringsplasser || 0}
          />
        );
      case 'Miljø':
        return (
          <MiljoPopup
            energimerke={miljoData?.energimerke || ''}
            energimerkeFarge={miljoData?.energimerkeFarge || ''}
            planlagtEnergimerke={miljoData?.planlagtEnergimerke}
            planlagtEnergimerkeFarge={miljoData?.planlagtEnergimerkeFarge}
            energiattest={miljoData?.energiattest}
            miljosertifisert={miljoData?.miljosertifisert || ''}
            kwh={miljoData?.kwh || undefined}
          />
        );
      case 'Overtakelse':
        return (
          <OvertakelsePopup
            gjennomforingstidTilByggeklar={gjennomforingstidTilByggeklar}
            generellInfoLedigFraUtc={generellInfoLedigFraUtc}
          />
        );
      case 'Fleksibilitet':
        return (
          <FleksibilitetPopup
            bebygdStatus={bebygdStatus}
            tilbudtAreal={tilbudtAreal}
            utbyggingspotensialeBTA={utbyggingspotensialeBTA}
            totaltAreal={totaltAreal}
            ledigAreal={ledigAreal}
            arealPerPlan={arealPerPlan}
            universellUtforming={universellUtforming}
          />
        );
      case 'Tomtepris':
        return <TomteprisPopup prisKvmUtenTiltak={prisKvmUtenTiltak} prisKvmMedTiltak={prisKvmMedTiltak} />;
      case 'Leiepris':
        return (
          <LeieprisPopup
            prisKvmUtenTiltak={prisKvmUtenTiltak}
            prisKvmMedTiltak={prisKvmMedTiltak}
            felleskostKvm={felleskostKvm}
            parkeringsplasskost={parkeringsData?.parkeringsplasskost || 0}
            sykkelplasskost={parkeringsData?.sykkelplasskost || 0}
            kostKantineBidrag={kostKantineBidrag}
            mvaKompensasjonKvm={mvaKompensasjonKvm}
            paslagFellesarealiProsent={paslagFellesarealiProsent}
          />
        );
      case 'Regulering':
        return (
          <>
            <Text variant="paragraph-header" component="h3">
              Regulering
            </Text>
            <Text variant="paragraph">{reguleringsstatus}</Text>
          </>
        );
      case 'Infrastruktur':
        return (
          <>
            <Text variant="paragraph-header" component="h3">
              Infrastruktur
            </Text>
            <Text variant="paragraph">{infrastruktur}</Text>
          </>
        );
      case 'Grunnforhold':
        return (
          <>
            <Text variant="paragraph-header" component="h3">
              Grunnforhold
            </Text>
            <Text variant="paragraph">{grunnforhold}</Text>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className={scores?.length <= 5 ? style.Rating : style.RatingMultiRows}>
      {scores?.map((score, index) => {
        const Component = getPopupComponent(score.type);
        return (
          <ScoreUnit
            key={index}
            score={score}
            hideExpand={hideExpand}
            visVurdering={visVurdering}
            overstyrVurderingUrl={overstyrVurderingUrl}
            title={score.type}
          >
            {Component}
          </ScoreUnit>
        );
      })}
    </div>
  );
};

export default ScoreRow;
