import Table from 'components/Table/Table';
import React, { FC, useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import Panel from 'components/Panel/Panel';
import { SvarTableItem } from 'types/SvarTableItem';
import Text from 'components/Text/Text';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from 'components/DropdownMenu';
import { IconTypes } from 'components/Icon/IconReactIcons';

import SvarItemModal, { ModalOperations, ModalProps } from 'components/SvarItemModal/SvarItemModal';
export interface SvarListeSide {
  readonly tilKonkurranse: SvarTableItem[];
  readonly aktiveSvar: SvarTableItem[];
  readonly paabegynteSvar: SvarTableItem[];
  readonly historiskeSvar: SvarTableItem[];
}

const defaultSvarItemModalProps: ModalProps = {
  isOpen: false,
  targetUrl: undefined,
  tableItemId: undefined,
  operation: ModalOperations.Delete
};

const SvarListeSide: FC<SvarListeSide> = ({ ...props }) => {
  const [svarItemModalData, setSvarItemModalData] = useState<ModalProps>(defaultSvarItemModalProps);

  const SvarActionsDropDown: FC<SvarTableItem> = ({ slettTilbudUrl, redigerUrl, trekkTilbudUrl, detaljUrl, id }) => {
    if (!slettTilbudUrl && !redigerUrl && !trekkTilbudUrl) {
      return (
        <>
          <div className="empty-cell"></div>
          <a title="Se svaret" href={detaljUrl}>
            <AiOutlineArrowRight />
          </a>
        </>
      );
    }
    return (
      <>
        <DropdownMenu>
          <DropdownMenuTrigger iconType={IconTypes.dotsVertical} className="test" />
          <DropdownMenuContent>
            {slettTilbudUrl && (
              <DropdownMenuItem asChild>
                <button
                  id="dropdown-menu-item-popup-btn"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu-item-popup"
                  onClick={() => {
                    setSvarItemModalData({
                      isOpen: true,
                      targetUrl: slettTilbudUrl,
                      tableItemId: id,
                      operation: ModalOperations.Delete
                    });
                  }}
                >
                  Slett
                </button>
              </DropdownMenuItem>
            )}
            {redigerUrl && (
              <DropdownMenuItem asChild>
                <a href={redigerUrl} style={{ textDecoration: 'none' }}>
                  Rediger
                </a>
              </DropdownMenuItem>
            )}
            {trekkTilbudUrl && (
              <DropdownMenuItem asChild>
                <button
                  id="dropdown-menu-item-popup-btn"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu-item-popup"
                  onClick={() => {
                    setSvarItemModalData({
                      isOpen: true,
                      targetUrl: trekkTilbudUrl,
                      operation: ModalOperations.Recind
                    });
                  }}
                >
                  Trekk tilbud
                </button>
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
        <a title="Se svaret" href={detaljUrl}>
          <AiOutlineArrowRight />
        </a>
      </>
    );
  };

  return (
    <div className="SvarListeSide">
      <SvarItemModal modalProps={svarItemModalData} tableItems={{ ...props }} setSvarItemModalData={setSvarItemModalData} />
      <Text component="h1" variant="article-h2">
        Konkurranse
      </Text>
      <Panel>
        <Table
          paging
          data={props.tilKonkurranse}
          iconsComponent={SvarActionsDropDown}
          columns={{
            ['Tittel']: 'tittel',
            ['Utleieobjekt']: 'tilbudtObjekt',
            ['Utleier']: 'utleier',
            ['Status']: 'status',
            ['Levert']: (x) => new Date(x.levertUtc).getTime(),
            ['Frist']: (x) => new Date(x.fristUtc).getTime()
          }}
          noItemsLabel="Ingen svar gått til konkurranse"
        />
      </Panel>
      <Text component="h1" variant="article-h2">
        Aktive
      </Text>
      <Panel>
        <Table
          paging
          data={props.aktiveSvar}
          iconsComponent={SvarActionsDropDown}
          columns={{
            ['Tittel']: 'tittel',
            ['Utleieobjekt']: 'tilbudtObjekt',
            ['Utleier']: 'utleier',
            ['Status']: 'status',
            ['Levert']: (x) => new Date(x.levertUtc).getTime(),
            ['Frist']: (x) => new Date(x.fristUtc).getTime()
          }}
          noItemsLabel="Ingen aktive svar"
        />
      </Panel>
      <Text component="h1" variant="article-h2">
        Påbegynte
      </Text>
      <Panel>
        <Table
          paging
          data={props.paabegynteSvar}
          iconsComponent={SvarActionsDropDown}
          columns={{
            ['Tittel']: 'tittel',
            ['Utleieobjekt']: 'tilbudtObjekt',
            ['Utleier']: 'utleier',
            ['Status']: 'status',
            ['Lagret']: (x) => new Date(x.sistOppdatertUtc).getTime(),
            ['Frist']: (x) => new Date(x.fristUtc).getTime()
          }}
          noItemsLabel="Ingen påbegynte svar"
        />
      </Panel>
      <Text component="h1" variant="article-h2">
        Historiske
      </Text>
      <Panel>
        <Table
          paging
          data={props.historiskeSvar}
          iconsComponent={SvarActionsDropDown}
          columns={{
            ['Tittel']: 'tittel',
            ['Utleieobjekt']: 'tilbudtObjekt',
            ['Utleier']: 'utleier',
            ['Status']: 'status',
            ['Levert']: (x) => new Date(x.levertUtc).getTime(),
            ['Frist']: (x) => new Date(x.fristUtc).getTime()
          }}
          noItemsLabel="Ingen historiske svar"
        />
      </Panel>
    </div>
  );
};

export default SvarListeSide;
